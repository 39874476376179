import { Notice } from "emoreg/atoms/Notice";
import { useTranslation } from "react-i18next";

export const XimiMigrationWarningAtConnection = () => {
  const { t } = useTranslation();
  return (
    <Notice type="orange">
      <strong>Le système sera en maintenance le 11 juillet !</strong>
      <br />
      Ximi déplace nos données pour accélérer notre système :
      <ul>
        <li>La déclaration d'absence sur l'application {t("caregiver_short")} sera désactivée.</li>
        <li>En fin de journée, Hassibot sera complètement hors-service pendant quelques heures.</li>
      </ul>
    </Notice>
  );
};

export const XimiMigrationWarning = () => {
  const { t } = useTranslation();
  return (
    <Notice type="orange">
      <strong>Le système sera en maintenance le 11 juillet !</strong>
      <br />
      Ximi déplace nos données pour accélérer notre système. La déclaration d'absence sur
      l'application {t("caregiver_short")} sera désactivée. En fin de journée, Hassibot sera
      complètement hors-service pendant quelques heures.
    </Notice>
  );
};
