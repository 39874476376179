import { OhApi } from "siayuda";

import { deleteUserCredentials, userCredentialsSubject } from "token-manager";
// siayuda does not allow us to have a "logged out" client, we hack it with
// this.
const FAKE_TOKEN = "FAKE_INVALID_TOKEN";

export const auth = new OhApi.AuthConfig("HB", FAKE_TOKEN);

class LegalEntityRestrictedApiClient extends OhApi.ApiV2Client {
  legalEntityUuid: string | null = null;

  public setLegalEntityUuid = (legalEntityUuid: string): void => {
    this.legalEntityUuid = legalEntityUuid;
  };

  public buildHeaders = (additionalHeaders?: Record<string, string>): Headers =>
    new Headers({
      ...(this.config.auth && this.config.auth.makeAuthHeader()),
      ...(this.legalEntityUuid && { "X-LEGAL-ENTITY-UUID": this.legalEntityUuid }),
      ...additionalHeaders,
    });
}

export const client = new LegalEntityRestrictedApiClient({
  auth,
  preProcessingResponseHooks: [
    async (resp): Promise<void> => {
      if (resp.status === 401) {
        await deleteUserCredentials();
      }
    },
  ],
});

userCredentialsSubject.subscribe(creds => {
  client.updateToken(creds ? creds.token : FAKE_TOKEN);
});
